* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

.preload * {
  transition: none !important;
}
.preload {
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in config
html {
  background: $light;
  box-sizing: border-box;
  color: $dark;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: rem($baseLineHeight);
  scroll-behavior: smooth;
  font-weight: 300;
  color:$primary;

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;
}

body {
  line-height: rem($baseLineHeight);
  opacity: 1 !important;
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style:normal;
}
.prologTxt {
  font-weight: 700;
  color:$black;
}


/* EXTENDAREA // GLOBALS */

.paddingSectionNoneBaseLineHeight {
  padding: rem($paddingDefault) 0 calc(#{rem($paddingDefault)} - #{rem($baseLineHeight)});
  @include breakpoint(medium) {
    padding: rem($paddingMedium) 0 calc(#{rem($paddingMedium)} - #{rem($baseLineHeight)});  
  }
  @include breakpoint(large) {
    padding: rem($paddingLarge) 0 calc(#{rem($paddingLarge)} - #{rem($baseLineHeight)});
  }
  @include breakpoint(giant) {
    padding: rem($paddingGiant) 0 calc(#{rem($paddingGiant)} - #{rem($baseLineHeight)});  
  }
  @include breakpoint(huge) {
    padding: rem($paddingHuge) 0 calc(#{rem($paddingHuge)} - #{rem($baseLineHeight)});
  }
  @include breakpoint(full) {
    padding: rem($paddingFull) 0 calc(#{rem($paddingFull)} - #{rem($baseLineHeight)});
  }  
}
.paddingSectionSmallNoneBaseLineHeight {
  padding: rem($paddingSmallDefault) 0 calc(#{rem($paddingSmallDefault)} - #{rem($baseLineHeight)});
  @include breakpoint(medium) {
    padding: rem($paddingSmallMedium) 0 calc(#{rem($paddingSmallMedium)} - #{rem($baseLineHeight)});  
  }
  @include breakpoint(large) {
    padding: rem($paddingSmallLarge) 0 calc(#{rem($paddingSmallLarge)} - #{rem($baseLineHeight)});
  }
  @include breakpoint(giant) {
    padding: rem($paddingSmallGiant) 0 calc(#{rem($paddingSmallGiant)} - #{rem($baseLineHeight)});  
  }
  @include breakpoint(huge) {
    padding: rem($paddingSmallHuge) 0 calc(#{rem($paddingSmallHuge)} - #{rem($baseLineHeight)});
  }
  @include breakpoint(full) {
    padding: rem($paddingSmallFull) 0 calc(#{rem($paddingSmallFull)} - #{rem($baseLineHeight)});
  }  
}

.paddingSectionSmall {
  padding: rem($paddingSmallDefault) 0;
  @include breakpoint(small) {
  }
  @include breakpoint(medium) {
    padding: rem($paddingSmallMedium) 0;
  }
  @include breakpoint(large) {
    padding: rem($paddingSmallLarge) 0;
  }
  @include breakpoint(giant) {
    padding: rem($paddingSmallGiant) 0;
  }
  @include breakpoint(huge) {
    padding: rem($paddingSmallHuge) 0;
  }
  @include breakpoint(full) {
    padding: rem($paddingSmallFull) 0;
  }   
}


.paddingSection {
  padding: rem($paddingDefault) 0;
  @include breakpoint(small) {
  }
  @include breakpoint(medium) {
    padding: rem($paddingMedium) 0;
  }
  @include breakpoint(large) {
    padding: rem($paddingLarge) 0;
  }
  @include breakpoint(giant) {
    padding: rem($paddingGiant) 0;
  }
  @include breakpoint(huge) {
    padding: rem($paddingHuge) 0;
  }
  @include breakpoint(full) {
    padding: rem($paddingFull) 0;
  } 
}

.paddingSectionBig {
  padding: rem($paddingBigDefault) 0;
  @include breakpoint(small) {
  }
  @include breakpoint(medium) {
    padding: rem($paddingBigMedium) 0;
  }
  @include breakpoint(large) {
    padding: rem($paddingBigLarge) 0;
  }
  @include breakpoint(giant) {
    padding: rem($paddingBigGiant) 0;
  }
  @include breakpoint(huge) {
    padding: rem($paddingBigHuge) 0;
  }
  @include breakpoint(full) {
    padding: rem($paddingBigFull) 0;
  } 
}

.marginSection {
  margin: rem($paddingDefault) 0;
  @include breakpoint(small) {
  }
  @include breakpoint(medium) {
    margin: rem($paddingMedium) 0;
  }
  @include breakpoint(large) {
    margin: rem($paddingLarge) 0;
  }
  @include breakpoint(giant) {
    margin: rem($paddingGiant) 0;
  }
  @include breakpoint(huge) {
    margin: rem($paddingHuge) 0;
  }
  @include breakpoint(full) {
    margin: rem($paddingFull) 0;
  } 
}

hr, .hr {
  display: flex;
  background: rgba($primary,.2);
  border: none;
  clear: both;
  height: rem(1px);
  flex-basis: 100%;
  margin: calc(#{rem($paddingSmallDefault)} - #{rem($baseLineHeight)}) 0 rem($paddingSmallDefault); 
  
  @include breakpoint(medium) {
    margin: calc(#{rem($paddingSmallMedium)} - #{rem($baseLineHeight)}) 0 rem($paddingSmallMedium);   
  }
  @include breakpoint(large) {
    margin: calc(#{rem($paddingSmallLarge/1.5)} - #{rem($baseLineHeight)}) auto rem($paddingSmallLarge/1.5);   
    .index & {
      width: rem(400px);
    }
  }

  @include breakpoint(giant) {
    margin: calc(#{rem($paddingSmallGiant/1.5)} - #{rem($baseLineHeight)}) auto rem($paddingSmallGiant/1.5);  
    .index & {
      width: rem(450px);
    } 
  }

  @include breakpoint(huge) {
    margin: calc(#{rem($paddingSmallHuge/1.5)} - #{rem($baseLineHeight)}) auto rem($paddingSmallHuge/1.5);   
    .index & {
      width: rem(550px);
    }
  }
  @include breakpoint(full) {
    margin: calc(#{rem($paddingSmallFull/1.5)} - #{rem($baseLineHeight)}) auto rem($paddingSmallFull/1.5); 
    .index & {
      width: rem(640px);
    }
  }
  &.sameHeight {
    margin:rem($paddingDefault) 0;  
  
    @include breakpoint(medium) {
      margin:rem($paddingMedium/1.5) 0; 
    }
    @include breakpoint(large) {
      margin:rem($paddingLarge/2.5) 0;  
    }

    @include breakpoint(giant) {
      margin:rem($paddingGiant/2.5) 0;  
    }

    @include breakpoint(huge) {
      margin:rem($paddingHuge/2.5) 0; 
    }
    @include breakpoint(full) {
      margin:rem($paddingFull/2.5) 0;
    } 
  }
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;
  line-height:130%;
  font-family: $displayFont;
  color:$primary;
  font-weight: 400;
  margin-bottom: rem($baseLineHeight/2);
  position: relative;
  @include breakpoint(huge) {
    margin-bottom: rem($baseLineHeight);
  }

  /* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

h1,
.h1
{
  font-size: rem($h1Size/2.1);
  line-height: rem(28px);
  color:$dark;

  @include breakpoint(medium) {
    font-size: rem($h1Size/1.6); 
    line-height: rem(32px);  
  }
  @include breakpoint(large) {
    font-size: rem($h1Size/1.5); 
    line-height: rem(35px);   
  }
  @include breakpoint(giant) {
    font-size: rem($h1Size/1.4);  
    line-height: rem(37px);
  }
  @include breakpoint(huge) {
    font-size: rem($h1Size/1.3);
    line-height: rem(39px);
  }
  @include breakpoint(full) {
    font-size: rem($h1Size);
    line-height: rem(45px);
  }
  body:not(.index) & {
    
  }
  .sub {
    display: block;
    font-size: rem(14px);
    letter-spacing: rem(2px);
    color:$primary;
    @include breakpoint(large) {
      font-size: rem(16px);
    }
    @include breakpoint(giant) {
      font-size: rem(18px);
    }
    @include breakpoint(huge) {
      font-size: rem(20px);
    }
    @include breakpoint(full) {
      font-size: rem(26px);
    }
  }
}

h2,
.h2
{
  font-size: rem($h2Size/1.5);
  line-height: rem(24px);
  color:$dark;

  @include breakpoint(medium) {
    font-size: rem($h2Size/1.4); 
    line-height: rem(26px);  
  }
  @include breakpoint(large) {
    font-size: rem($h2Size/1.3); 
    line-height: rem(28px);   
  }
  @include breakpoint(giant) {
    font-size: rem($h2Size/1.2);  
    line-height: rem(30px);
  }
  @include breakpoint(huge) {
    font-size: rem($h2Size/1.1);
    line-height: rem(32px);
  }
  @include breakpoint(full) {
    font-size: rem($h2Size);
    line-height: rem(35px);
  }
  body:not(.index) & {
    margin-bottom: rem($baseLineHeight/2.5);
    @include breakpoint(large) {
      font-size: rem($h2Size/1.3); 
      line-height: rem(28px);   
    }
  }
}

h3,
.h3 {
  font-size: rem($h3Size/2);
  line-height: rem(31px);
  @include breakpoint(giant) {
    font-size: rem($h3Size/1.3); 
    line-height: rem(33px);
  }
  @include breakpoint(huge) {
     font-size: rem($h3Size/1.2); 
     line-height: rem(35px);
  }
  @include breakpoint(full) {
     font-size: rem($h3Size); 
     line-height: rem(39px);
  }
}

h4,
.h4 {
  font-size: rem($h4Size/2);
  margin-bottom: rem($baseLineHeight/2);
  @include breakpoint(large) {
    font-size: rem($h4Size/1.75);
  }
  @include breakpoint(huge) {
    font-size: rem($h4Size/1.75);
  }
  @include breakpoint(full) {
    font-size: rem($h4Size);
  }
}

h5,
.h5 {
  font-size: rem($h5Size/1.5);
 margin-bottom: rem($baseLineHeight/2);
  @include breakpoint(huge) {
     font-size: rem($h5Size);
  }
  &:before {
    display: none;
  }
}

h6,
.h6 {
  font-size: rem($h6Size);
}

/**
 * Links
 */
a {
  color: $primary;
  word-wrap: break-word;

  &:hover {
    color: $secondary;
  }
  &:focus {
    color: darken($secondary, 50%);
  }
  &:active {
    color: darken($secondary, 30%);
  }

  img {
    border: none;
  }

  &[href^="tel"] {
    color: inherit;
    text-decoration: underline;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

//OBJECTFIT
figure {
  display: block;
  width: 100%;

  &.objectFit {
    display: block;
    background-size: cover;
    background-position: center;
    margin-bottom: 0;
    height: rem(300px);

    @include breakpoint(tiny) {
      height: 100%;
    }
    @include breakpoint(small) {
     
    }
    @include breakpoint(medium) {
    }
    @include breakpoint(large) {
    }
    @include breakpoint(giant) {
    }
    @include breakpoint(huge) {
      
    }
    @include breakpoint(full) {
    
    }

    
    @include breakpoint(medium) {
      // height: 100%;
    }

    body:not(.index) & {
      @include breakpoint(medium) {
        height: 100%;
      }
    }
    img {
      max-height: 100%;
      height: 100%;
      object-fit: cover;
      object-position:top;
     
      .internetExplorer11 & {
        display: none;
      }
    }
  }
}

strong, b {
  font-weight: 700;
  color:$dark;
}

ul,
ol {
  list-style: none;

  &.styledList {
    li {
      padding-left: rem(15px);
      margin-bottom: rem(7px);

      &:before {
        @extend .icon;
        @extend .icon-angle-right-small;
        text-indent: rem(-15px);
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}



dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: rgba($medium, 0.8);
      padding: rem(15px);
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
    }

    dd {
      padding-top: 0;

      + dt {
        margin-top: rem(10px);
      }

      + dd {
        margin-top: rem(-15px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  &.styledList
  {
    counter-reset: item;
  
    li {
      counter-increment: item;
  
      &:before {
        content: counters(item, ".") ". ";
      }
    }
  
    ol {
      margin-bottom: 0;
  
      li {
        &:before {
          content: counters(item, ".") " ";
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 0;
  }

  &[data-src] {
    @extend %lazyloading;
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}
.popUpContainer {
 
  body:not(.cmsBackend) & {
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    transition: opacity .3s, visibility .3s;
    position: fixed;
    visibility: visible;
    opacity: 1;
    z-index: 1000000;
 
    .popUpContent {
      width: 100%;
      background: transparent;
      max-height: 90vh;
      max-width: calc(100% - #{rem(40px)}); // for the close label
      overflow: hidden;
      overflow-y: auto;
      @include breakpoint(small) {
        max-width: calc(100% - #{rem(110px)}); // for the close label
      }
      @include breakpoint(giant) {
        max-width: 80%;
      }
   
      @include breakpoint(full) {
        max-width: 69%;
      }
      .h1 {
        color:$light;
        text-align: center;
      }
      .openingHours {
        text-align: center;
        color:$light;
        @include breakpoint(medium) {
          font-size: rem(20px);
          line-height: rem(30px);
        }
        dt {
          font-weight: 700;
        }
      }
    }
  }
 
  label {
    display:none;
 
    body:not(.cmsBackend) & {
      background: rgba(black, 0.8);
      display: flex;
      height: 100%;
      justify-content: flex-end;
      padding: rem($baseGap);
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: -1;
   
      i {
        cursor: pointer;
        display: block;
        height: rem(35px);
        width: rem(35px);
        position: relative;
        transition: background .3s;
   
        &:hover {
          background: rgba(black, 0.8);
   
          &:before, &:after {
            background: white;
          };
        }
   
        &:before, &:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: rem(30px);
          height: rem(2px);
          background: #ddd;
        }
   
        &:before {
          transform: translate(-50%,-50%) rotate(45deg);
        }
   
        &:after {
          transform: translate(-50%,-50%) rotate(-45deg);
        }
      }
    }
  }
}
 
input[id^="popUpToggled"] {
  display: none;
 
  &:not(:checked) {
 
    + {
 
      .popUpContainer {
        position: relative;
        opacity: 0;
        visibility: hidden;
        z-index: -1000000;
        transform:scale(0);
        pointer-events: none;
        width: 0;
        height: 0;
      }
    }
  }
}