$scheme: "bright"; // bright or dark scheme, e.g. for icons

form.default {
	width: 100%;
	overflow: hidden;

	&.disabled {
		input, textarea, select, button, label {
			pointer-events: none;
			opacity:0.5;

			a
			{
				pointer-events: all;
			}
		}
	}

	.fieldBorder {
		border-left: 1px solid lighten($border, 5%);
		border-top: 1px solid lighten($border, 5%);
		border-bottom: 1px solid $border;
		border-right: 1px solid $border;
	}

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: invert($light);
		opacity: .5;
	}
	::-moz-placeholder {
		/* Firefox 19+ */
		color: invert($light);
		opacity: .5;
	}
	:-ms-input-placeholder {
		/* IE 10+ */
		color: invert($light) !important;
		opacity: .5;
	}
	:-moz-placeholder {
		/* Firefox 18- */
		color: invert($light);
		opacity: .5;
	}

	fieldset {
		border: none;
	}
	legend {
		@extend .h2;
	}

	label {
		color: inherit;
		display: block;
		padding: 1rem 0 0.6rem .9rem;
		position: relative;
		font-size:90%;
		font-weight: 700;

		small {
			color: $four;
			display: inline-block;
			line-height: 0.5rem;
		}

		input:checked + span:after {
			opacity: 1;
		}
	}

	[type="text"],
	[type="number"],
	[type="tel"],
	[type="email"],
	[type="file"],
	[type="date"],
	textarea,
	.inputUl,
	select {
		@extend %animatedTransform;
		@extend .fieldBorder;
		background: $light;
		color: $dark;
		display: block;
		font-family: $mainFont;
		font-size: rem($baseFontSize);
		line-height: rem($baseLineHeight);
		margin: 0 0 0.5rem 0;
		padding: 0.8rem;
		position: relative;
		resize: none;
		width: 100%;
		border-radius: 0;

		&:focus {
			background: darken($light, 2%);
			outline: none;
		}
	}
	.inputUl {
		li {
			padding-left: 0;
			&:before {
				display: none;
			}
		}
	}
	select {
		appearance: none;
	}

	input:not([type=submit]):not([type=checkbox]):not([type=radio]), textarea, select {
		&:required, &:invalid {
			background-repeat: no-repeat;
			background-position: .7rem .5rem;
			background-size: 12px;
			background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
			padding-left: 1.9em
		}
	
		&:required {
			background-size: 6px;
			background-position: .7rem 1.34rem;

			@if $scheme == "bright" {
				background-image: url(map-get($bgIcons, 'blackStar'));
			} @else if $scheme == "dark" {
				background-image: url(map-get($bgIcons, 'whiteStar'));
			}
		}
		
		&:required:valid {
			background-position: .7rem 1.2rem;
			background-size: 12px;

			@if $scheme == "bright" {
				background-image: url(map-get($bgIcons, 'blackCheck'));
			} @else if $scheme == "dark" {
				background-image: url(map-get($bgIcons, 'whiteCheck'));
			}
		}
		
		&:not(:placeholder-shown):required:invalid {
			background-position: .7rem 1.3rem;
			background-size: 9px;

			@if $scheme == "bright" {
				background-image: url(map-get($bgIcons, 'blackTimes'));
			} @else if $scheme == "dark" {
				background-image: url(map-get($bgIcons, 'whiteTimes'));
			}
		}
	}

	div {
        &.checkbox, &.radio, .checkbox{
            display: flex;
            align-items: center;

            &:not(:last-child) {
                @extend .marginBottom;
            }

			input {
				opacity: 0;
				position: absolute;
				pointer-events: none;

				&:checked + label:after {
					opacity: 1;
				}
			}

			label {		
				padding-top:0;
				padding-bottom:0;
				padding-left: 2rem;

                &:not(:last-of-type) {
                    margin-right: 2rem;
				}
					
				&:before {
					content:"";
					background-color: $light;
					@extend .fieldBorder;
					width: 18px;
					height: 18px;
					display: inline-block;
					position: absolute;
					top: rem(3px);
					left: 0;
					cursor:pointer;
					border-radius: 2px;
				}

				&:after {
					transition: 100ms all ease-in-out;
					content:"";
					width: 8px;
					height:8px;
					display: inline-block;
					background-size:100%;
					top: rem(8px);
					left: rem(5px);
					position: absolute;
					pointer-events: none;
					opacity:0;

					@if $scheme == "bright" {
						background-image: url(map-get($bgIcons, 'blackCheck'));
					} @else if $scheme == "dark" {
						background-image: url(map-get($bgIcons, 'whiteCheck'));
					}
				}
			}
		}
		&.radio {
			label {
				&:before {
					border-radius:50%;
				}
			}
		}
		&.select {
			@extend .marginBottom;
			position: relative;

			select {
				padding-right: 2.4rem;
			}
			&:before {
				@extend .icon;
				@extend .icon-angle-right;
				position: absolute;
				line-height: inherit;
				right: 1rem;
				bottom: 0.85rem;
				z-index: 1;
				pointer-events: none;
				transform: rotate(90deg);
			}
		}
    }

	button,
	[type="submit"] {
		@extend .btn;
		margin: 0 0 1.75rem;
    }
}

/*=VALIDATION */
.specialfield {
	display: none !important;
	visibility: hidden !important;
}

#newsletter-info {
	background: $alert;
	color: $light;
	font-weight: bold;
	margin-bottom: $baseLineHeight;
	padding: $baseGap;

	*:last-of-type {
		margin-bottom: 0;
	}
}

img[name="vimg"] + input[name="imgverify"] {
	float: right;
}