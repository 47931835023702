/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 16px;

$baseLineHeight: 30px;

$realFontSize: 16px;

$h1Size: 55px;

$h2Size: 30px;

$h3Size: 25px;

$h4Size: 25px;

$h5Size: 22px;

$h6Size: 16px;

$mainFont: Spartan, sans-serif;

$displayFont: Staatliches, sans-serif;

$iconFont: "icomoon", sans-serif;

// Project colors

$primary: #636363;
$secondary: #37a7e5;
$third: #f5f5f5;
$four: #187fb8;
$light: #fff;
$medium: #F2F2F0;
$dark: #0d1014;
$black: #000;
$border: #bbb;
$alert: #D85D00;

$baseGap: 18px;

$rowMaxWidthLarge: 992px;

$rowMaxWidthGiant: 1200px;

$rowMaxWidthHuge: 1364px;

$rowMaxWidth: 1620px;

$heightDefault: 55px;

$heightMedium: 60px;

$heightLarge: 110px;

$heightGiant: 120px;

$heightHuge: 130px;

$heightFull: 160px;

$paddingSmallDefault: 30px;

$paddingSmallMedium: 40px;

$paddingSmallLarge: 50px;

$paddingSmallGiant: 60px;

$paddingSmallHuge: 80px;

$paddingSmallFull: 100px;

$paddingDefault: 60px;

$paddingMedium: 80px;

$paddingLarge: 100px;

$paddingGiant: 130px;

$paddingHuge: 150px;

$paddingFull: 200px;

$paddingBigDefault: 150px;

$paddingBigMedium: 200px;

$paddingBigLarge: 250px;

$paddingBigGiant: 300px;

$paddingBigHuge: 400px;

$paddingBigFull: 560px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "×", 
	minus: "-", 
	angle-right: "❯", 
	plus: "+", 
	angle-up: "↑", 
	exclamation: "!", 
	clock: "\e96a", 
	angle-down: "\ea1d", 
	angle-right-small: "\ea2a", 
	phone: "\ea82", 
	mail: "\e9b0", 
	marker: "\ea3d", 
	swipe: "\e9e2", 
	angle-right-line: "\ea1f", 
	pencil: "\ea7a", 
	play: "\ea29", 
	tools: "\e993", 
	chain: "\ea2d", 
	search: "\eabf", 
	persontalk: "\ea27", 
	settings: "\eac1", 
	ruler: "\eab7", 
	lkw: "\ea30", 
	bed: "\e923", 
	bin: "\e930", 
	bone: "\e935", 
	clothes: "\e96f", 
	heart: "\ea00", 
	parking: "\ea6e", 
	euro: "\ea73", 
	wheel: "\eb50", 
	ship: "\eac9", 
	sun: "\eb07", 
	global: "\e9f1", 
	person: "\ea28", 
	bike: "\ea4d", 
	check: "\eaa7", 
	car: "\e946", 
	car-wheel: "\eb4f", 
	paper-mail: "\ea6d", 
	hand-stop: "\e9fc", 
	present: "\ea94", 
);

$bgIcons: (
	blackStar: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsLXJ1bGU6IGV2ZW5vZGQ7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxwYXRoIGlkPSJTdGVybl9taXRfNV9aYWNrZW5fMSIgZGF0YS1uYW1lPSJTdGVybiBtaXQgNSBaYWNrZW4gMSIgY2xhc3M9ImNscy0xIiBkPSJNMzItLjA0NEw0Mi41NDYsMjAuMDgyLDY0LDI0LjQxOCw0OS4wNjQsNDEuMTkzLDUxLjc3NSw2NCwzMiw1NC4yMzksMTIuMjI1LDY0bDIuNzEtMjIuODA2TDAsMjQuNDE4bDIxLjQ0OS00LjMzNkwzMi0uMDQ0Ii8+Cjwvc3ZnPgo=", 
	blackTimes: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsLXJ1bGU6IGV2ZW5vZGQ7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxwYXRoIGlkPSJSZWNodGVja18xIiBkYXRhLW5hbWU9IlJlY2h0ZWNrIDEiIGNsYXNzPSJjbHMtMSIgZD0iTTUxLjc1MSw2LjVMNTcuNSwxMi4yNDksMTIuMjQ5LDU3LjUsNi41LDUxLjc1MSw1MS43NTEsNi41Ii8+CiAgPHBhdGggaWQ9IlJlY2h0ZWNrXzFfS29waWUiIGRhdGEtbmFtZT0iUmVjaHRlY2sgMSBLb3BpZSIgY2xhc3M9ImNscy0xIiBkPSJNNTcuNSw1MS43NTFMNTEuNzUxLDU3LjUsNi41LDEyLjI0OSwxMi4yNDksNi41LDU3LjUsNTEuNzUxIi8+Cjwvc3ZnPgo=", 
	blackCheck: "data:image/svg+xml;base64,PHN2ZyBpZD0iQ2FwYV8xIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTUuNTU2IDUxNS41NTYiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTE1LjU1NiA1MTUuNTU2IiB3aWR0aD0iNTEyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0wIDI3NC4yMjYgMTc2LjU0OSAxNzYuODg2IDMzOS4wMDctMzM4LjY3Mi00OC42Ny00Ny45OTctMjkwLjMzNyAyOTAtMTI4LjU1My0xMjguNTUyeiIvPjwvc3ZnPg==", 
	whiteStar: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZmZmOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9IlN0ZXJuX21pdF81X1phY2tlbl8xIiBkYXRhLW5hbWU9IlN0ZXJuIG1pdCA1IFphY2tlbiAxIiBjbGFzcz0iY2xzLTEiIGQ9Ik0zMi0uMDQ0TDQyLjU0NiwyMC4wODIsNjQsMjQuNDE4LDQ5LjA2NCw0MS4xOTMsNTEuNzc1LDY0LDMyLDU0LjIzOSwxMi4yMjUsNjRsMi43MS0yMi44MDZMMCwyNC40MThsMjEuNDQ5LTQuMzM2TDMyLS4wNDQiLz4KPC9zdmc+Cg==", 
	whiteTimes: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZmZmOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9IlJlY2h0ZWNrXzEiIGRhdGEtbmFtZT0iUmVjaHRlY2sgMSIgY2xhc3M9ImNscy0xIiBkPSJNNTEuNzUxLDYuNUw1Ny41LDEyLjI0OSwxMi4yNDksNTcuNSw2LjUsNTEuNzUxLDUxLjc1MSw2LjUiLz4KICA8cGF0aCBpZD0iUmVjaHRlY2tfMV9Lb3BpZSIgZGF0YS1uYW1lPSJSZWNodGVjayAxIEtvcGllIiBjbGFzcz0iY2xzLTEiIGQ9Ik01Ny41LDUxLjc1MUw1MS43NTEsNTcuNSw2LjUsMTIuMjQ5LDEyLjI0OSw2LjUsNTcuNSw1MS43NTEiLz4KPC9zdmc+Cg==", 
	whiteCheck: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZmZmOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9IlJlY2h0ZWNrXzFfS29waWUiIGRhdGEtbmFtZT0iUmVjaHRlY2sgMSBLb3BpZSIgY2xhc3M9ImNscy0xIiBkPSJNMjIuNiw1NS4zMkw2NC4zMiwxMy42LDU4LjY2Myw3Ljk0NCwyMi42LDQ0LjAwNiw2LjMzNywyNy43NDMsMC42OCwzMy40WiIvPgo8L3N2Zz4K", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
		center: (justify-content:center),
		cols: (11),
		middle: (align-items:center),
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (6, 10),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (5, 6, 7, 10),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (4, 5, 6, 7),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (4, 5, 6, 7, 8, 12),
		end: (justify-content:flex-end),
		middle: (align-items:center),
		prefix: (1),
		suffix: (1),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (3, 4, 7),
		prefix: (1),
	),
	huge: (
		width: em(1364px, $bpContext), 
	),
	full: (
		width: em(1620px, $bpContext), 
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
);

