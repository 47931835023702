// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $dark; // Navigation Background
$navColor: $light; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: rem($baseGap) rem(11px);
	text-decoration: none;
	color: $light;
	font-size: rem(12px);
	transition:.35s all;
	line-height: rem(22px);
	position: relative;
	font-weight: 300;
	@include breakpoint(medium) {
		font-size: rem(14px);
    	padding: rem(25px) rem($baseGap);
	}
	@include breakpoint(large) {
		color:$dark;
		padding: 0;
		font-size: rem(12px);
		line-height: 100%;
		font-weight: 700;
	}
	@include breakpoint(giant) {
		font-size: rem(14px);	
	}
	@include breakpoint(huge) {
		font-size: rem(15px);
	}
	@include breakpoint(full) {
		font-size: rem(16px);	
	}
}
%buttonHover {
	// Link Hover
	color: $light;
	background-color: $secondary;
	@include breakpoint(large) {
		background-color: transparent;
		color: $secondary;
	}
}
%buttonActive {
	// Link Active
	color: $light;
	background-color: $secondary;
	font-weight: 700;
	@include breakpoint(large) {
		background-color: transparent;
		color: $secondary;
		font-weight: 700;
	}
	@include breakpoint(full) {	
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-down);
	transform: rotate(90deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-down);

	@include breakpoint($break) {
		margin: 0 !important;
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: $heightDefault; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $light; // background-color
$barColor: $black; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 10;
	padding-top: rem($heightDefault);
	transition: .35s all;
	@include breakpoint(medium) {
		padding-top: rem($heightMedium);	
	}

	.mobileOnly {
		position: absolute;
		left:0;
		top:0;
		width: 50%;
		height: $navHeight;
		display: flex;
    	align-items: center;
    	justify-content: center;
    	z-index: 1011;
    	top: rem($heightDefault);
    	
    	@include breakpoint(medium) {
    		top: rem($heightMedium);
    		height: $navHeight$medium;	
    	}
		
		.all {
			display: block;
			text-align: right;
			.subMobile {
				display: block;
				
				li {
					display: block;
					line-height: rem(25px);
					a {
						font-size: rem(12px);
						color:rgba($light,.6);
						text-decoration: none;
						width: 100%;
						display: block;
						@extend %animatedTransform;

						@include breakpoint(medium) {
							font-size: rem(14px);	
							line-height: rem(33px);
						}
						&:hover, &.active {
							color:$light;
						}
					}
				}
			}
			.socialArea {
				display: block;
				margin: rem($baseLineHeight/2) 0;
				line-height: 100%;
				.fb,.insta, .whatsapp {
					display: inline-block;
					width: rem(18px);
					height: rem(18px);
					fill:$light;
					.f {
						fill:transparent;
					}
					.circle {
						fill:$light;
					}
					span {
						display: none;
					}
				}
				.fb, .insta {
					margin-right: rem($baseGap/2);
				}
				.insta {
					svg {
						fill:$light;
					}
				}
				.whatsapp {
					img {
						display: block;
					}
				}
			}
			
			.phone, .email {
				display: block;
				font-size: rem(12px);
				color:$light;
				text-decoration: none;

				@include breakpoint(medium) {
					font-size: rem(14px);
					line-height: rem(33px);
				}
			}
			.email {
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
			
		}
	}
	.desktopDisplay {
		display: none;
	}


	nav {
		display: flex;
    	align-items: center;
		width: 50%;
    	margin-left: 50%;
		@include ul {
			
			li {
				border-bottom: 1px solid rgba($light,.25); 
				//MAIN NONE MOBILE
				&.datenschutzerklaerung,
				&.impressum,
				&.anfahrt,
				&.links,
				&.agb,
				&.kontakt,
				&.oeffnungszeiten,
				&.bilder,
				
				 {
					display: none;
				}
				//BORDER NONE ELEMENT
				&.hersteller {
					border-bottom: none;
				}
				@include navButtons {
					@include navButton;
				}

				@include hasSub {
					@include sub {
						background: $navBg;
						width: 100%;
						z-index: 1020;
						@include breakpoint(tiny) {
							width: 50%;
						}
						&:before {
							padding: rem($baseGap);
							background-color: darken($secondary,15%);
							color:$light;
							font-size: rem(14px);
							text-align: center;
    						font-weight: 700;
			
						}
					}
				}
				@include navBack {
					
					display: none;
					.isTouch &, .isMobile &, .isTablet &  {
						display: block !important;
					}
					
				}
			}
			
		}
	}
	#impressMobile {
		position: fixed;
		top:0;
		left:0;
		width: 110%;
		height: 110%;
		@include responsiveImage('layout/nav.jpg', (tiny), true);
		z-index: -1;
		opacity: 0;
		visibility: hidden;
		transition:.55s all;
		.hiddenScroll & {
			opacity: 1;
			visibility: visible;	
		}
		@include breakpoint(large) {
			display: none;
		}
		&:before {
			content:'';
			position: absolute;
			top:0;
			left:0;
			background-color: rgba($black,.75);
			width: 100%;
			height: 100%;
			@include breakpoint(large) {
				display: none;
			}
		}
		&:after {
			content:'';
			position: fixed;
			left:50%;
			transform:translateX(-50%) translateX(-.5px);
			background-color:rgba($light,.25);
			width: 1px;
			top:0;
			height: 110%;
		}
		&.lazyLoaded {
			background-size:cover;
			@include breakpoint(large) {
				background-image:none;
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {

	@include desktopVersionFunctionality;

	#navigation {
		position: fixed;
		top:0;
		left:0;
		padding: 0;
		width: 100%;
		transition:none;
		background-color: $light;
		box-shadow: 0 0 0 rgba($dark,.1);
		transition:.55s all;		
		height: rem($heightLarge);
		body:not(.index) & {
			
		}
		@include breakpoint(giant) {
			height: rem($heightGiant);
		}
		@include breakpoint(huge) {
			height: rem($heightHuge);
		}
		@include breakpoint(full) {
			height: rem($heightFull);
		}
		.fixTool & {
			box-shadow:  0 0 rem(40px) rgba($black,.1);	
		}
		.fixed & {
			
			position: fixed;
			top:0;
				
			
			@include breakpoint(huge) {
				
			}
			@include breakpoint(full) {
				
			}
		}
		.cmsBackend & {
			z-index: 2;
			position: absolute;
		}

		body:not(.index) & {
		}

		.row {
			// max-width: rem($rowMaxWidthLarge);
			height: 100%;
			// @include breakpoint(giant) {
			// 	max-width: rem($rowMaxWidthGiant);
			// }
			// @include breakpoint(huge) {
			// 	max-width: rem($rowMaxWidthHuge);
			// }
			
			// @include breakpoint(full) {
			// 	max-width: rem($rowMaxWidth);
			// }
		}

		.noGutter {
			padding: 0 rem($baseGap);
			z-index: 1;
		}

		.desktopDisplay {
			display: block;
			position: relative;
			.branding {
				display: block;
				width: rem(220px);
				position: absolute;
				top:rem(-4px);
				left:rem($baseGap);
				transition:.2s all;
				transform-origin:center left;
				@include breakpoint(giant) {
					width: rem(240px);	
				}
				@include breakpoint(huge) {
					width: rem(280px);	
				}
				@include breakpoint(full) {
					width: rem(312px);	
				}
				.fixTool & {
					transform:translateY(-50%)  scale(.75);	
					top:0;
				}
				body:not(.index) & {
					transform:translateY(-50%)  scale(.75);	
					top:0;
				}
			}
		}
		.contactData {
			width: 100%;
			display: flex;
			justify-content:flex-end;
			line-height: 100%;
			margin-bottom: rem($baseGap/2);
			@include breakpoint(giant) {
				margin-bottom: rem($baseGap);
			}
			li {
				display: inline-block;
				margin-right: rem($baseGap);
				font-size: rem(12px);
				@include breakpoint(giant) {
					font-size: rem(14px)
				}
				@include breakpoint(huge) {
					font-size: rem(15px)
				}
				@include breakpoint(full) {
					font-size: rem(16px)
				}
				&:last-of-type {
					margin-right: 0;
				}
			}
		}

		// mobileContainer
		.mobileOnly {
			display: none;
		}

		nav {
			margin: 0;
			width: 100%;
			
			ul {
				&.navi {
					justify-content:space-between;
					@include breakpoint(full) {
						
					}
					li {
						border-bottom:none;
						&.hasSub {
							.sub {
								top:100%;
								transition:.35s all;
								position: absolute;
								top: calc(100% + #{rem(10px)});
								width: auto;
								background-color: transparent;

								li {
									transition:.55s all;
									text-align: center;
									background-color: $primary;
									text-align: left;
									
									&.navBack, a, .subToggler {
										padding: rem(12px) rem(14px);
										color:$light;
										font-weight: 400;
										border-bottom: 1px solid rgba($light,.2);
										@include breakpoint(huge) {
											
											padding: rem(15px) rem(18px);
										}
										@include breakpoint(full) {
											padding: rem(20px) rem(22px);
										}
									}
									&:last-child {
										&.navBack, a {
										
										}
									}
									&:hover, &.active {
										background-color: darken($secondary,5%);
										text-decoration: none;
										
									}
									&.navBack {
										width: 100%;
										&:before {
											position: static;
											transform:none;
											left:auto;
											top:auto;
											background-image: none;
										    opacity: 1;
										    visibility: visible;
											width: inherit;
											height: inherit;
										}
									}
									a, span {
										&:before {
											display: none;
										}
									}
								}
							}
						}
						
					}
				}
				
				//DESKTOP NONE LINKS
				&.naviMain {
					li {
						&.impressum,
						&.datenschutzerklaerung,
						&.agb,
						&.anfahrt,
						&.home,
						&.standort, 
						&.jobs {
							display: none;
							visibility: hidden;
						}
						&.home  {
							@include breakpoint(large) {
								display: block;
								visibility: visible;	
							}
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 rem($baseGap);
	z-index: 10;
	
	.fixTool & {
		box-shadow: 0 0 rem(30px) 0 rgba($primary,.25);		
	}
	@include breakpoint(medium) {
		height: rem($heightMedium);
	}
	@include breakpoint($break) {
		display: none;
	}
	.circleBtn {
		width: rem(30px);
		height: rem(30px);
		border-radius:100%;
		background-color: $dark;
		display: flex;
		justify-content:center;
		align-items:center;
		text-decoration: none;
		@include breakpoint(medium) {
			width: rem(40px);
			height: rem(40px);	
		}
		i {
			font-weight: 400;
			font-size: rem(16px);
			color:$light;
			@include breakpoint(medium) {
				font-size: rem(20px);	
			}
		}
	}
	.branding {
	    display: block;
	    justify-content: center;
	    align-items: center;
	    transition:.2s all;
	    width: rem(70px);
   		width: rem(125px);
	    position: absolute;
	    left: 50%;
	    transform: translateX(-50%);
	    transform-origin: center top;
	    top: rem(4px);
	    @include breakpoint(medium) {
	    	width: rem(150px);
	    }
		.fixTool &, .hiddenScroll & {
			transform: translateX(-50%) scale(.73);	
			@include breakpoint(medium) {
				transform: translateX(-50%) scale(.7);	
			}
		}
		img {
			display: block;
    		transition:.55s all;
		}
	}
}

// INCLUDE INFO

#times {
	position: absolute;
	bottom:0;
	position: fixed;
	right:0;
	background-color:$secondary;
	width: rem($heightDefault);
	height: rem($heightDefault);
	border:none;
	transition:.35s all ease;
	z-index: 12;
	transform:translateX(100%);
	cursor: pointer;

	.fixTool & {
		transform:translateX(0);	
	}
	
	&:hover {
		background-color: darken($secondary,15%);
		color:$light;
	}
	
    svg {
		width: rem(14px);
		height: rem(14px);
		fill:$light;
		display: inline-block;
		animation: rotate 2s ease infinite alternate;
		overflow: visible;
		transform-origin:center center;

		@keyframes rotate {
		  0% {
		    transform:rotate(0);
		  }
		  
		  100% {
		  	transform:rotate(360deg);
		  }
		}
	}
}


//ONCLICK
.time-in {
    z-index:11;
    position: fixed;
    bottom:rem($heightDefault);
    right: 0;
    text-align: center;
    visibility: hidden;
    transition:.35s all ease;
   	width: rem($heightDefault);
	
    
    a, button, >span {
       	width: rem($heightDefault);
		height: rem($heightDefault);
        display: flex;
	    justify-content: center;
	    align-items: center;
	    background-color: $primary;
		text-decoration: none;
		font-size: rem(18px);
		color:$light;
		border:none;
		text-align: center;
		transition:.35s all ease;
		opacity: 0;
		cursor: pointer;

		&:hover {
			background-color: $secondary;
			color:$light;
		}
        
        &.fb {
            opacity: 0;
            transition:.35s all ease;
        }

        &.phone {
        	transition:.35s all ease;
        	@include breakpoint(large) {
        		display: none;
        	}
        }
        &.marker {
            opacity: 0;		
            transition:.35s all ease;
        }
        &.mail {
        	opacity: 0;	
        	transition:.35s all ease;
        }
        &.sheet {
        	opacity: 0;	
        	transition:.35s all ease;
        }
        &.opentimes {
        	opacity: 0;	
        	background-color: $four;
        	color: $light;	
        	transition:.35s all ease;
        }
        
    }
    button {
		display: inline-block;
	}
    .opentimesDisplay {
		transform:translateX(calc(100% - #{rem(-$heightDefault)}));	
		position: fixed;
		right:rem($heightDefault);
		bottom:0;
		background-color: $four;
		padding: rem(20px);
		width: calc(100% - #{rem($heightDefault)});
		height: rem($heightDefault*5);
		color:$light;
		transition:.35s all;
		display: flex;
		flex-wrap:wrap;
		align-items: center;
	    align-content: center;
	    font-size: rem(12px);
	    text-align: center;
	    justify-content:center;
	    @include breakpoint(tiny) {
	    	width: rem(320px);
	    }
	    @include breakpoint(medium) {
	    	font-size: rem(14px);	
	    }
	    @include breakpoint(large) {
	    	height: rem($heightDefault*4);
	    }
		span {
			display: block;
			width: 100%;
			background-color: transparent;
			font-size: rem(13px);
			line-height: rem(23px);
			@include breakpoint(medium) {
				font-size: rem(14px);
				line-height: rem(26px);	
			}

			strong {
				display: block;
				color:$light;
			}
			span span {
				display: inline-block;
				width: auto;

			}
			.break {
				display: block;
			}
		}
		.h2 {
			color:$light;
			font-size: rem(18px);
			margin-bottom: rem($baseGap/2);
		}
		.btn {
			background-color: $third;
			font-size: rem(13px);
			&:first-of-type {
				margin-bottom: rem($baseGap/2);
			}
			&:hover {
				background-color: $dark;
			}
		}
	}
}




.active {
	
	.fixTool .time-in {
		visibility: visible;
		a, button {
		    &.fb, 
		    &.phone,
		    &.marker,
		    &.mail,
		    &.sheet,
		    &.opentimes {
		       
		        opacity: 1;
		    }
		   
	    }
	}
	.fixTool .opentimesDisplay {
		transform:translateX(0);
	}
}

#naviToggled {
  display: none;
 
	&:checked {
		~ * #quickbar,
		~ #quickbar {
			.branding {
				
			}
		}

		 html,
		 html {
			overflow: hidden;
		}

		~ * #impressMobile, ~ #impressMobile {
			opacity: 1;
			visibility: visible;
		}

	}
}

.fb {
	transition:.5s;

	&:hover {
		opacity: .7;
	}
}