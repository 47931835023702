// Use this for default Icomoon

@include fontFaceV2(
 $fontName: "icomoon",
 $fileName: "icomoon",
 $types: (woff, ttf, svg, eot),
 $style: normal,
);

// Example font-face for Roboto
@include fontFaceV2( $fontName: "Spartan", $fileName: "spartan-v2-latin", $weights: ("300", "700"), $types: woff);
@include fontFaceV2( $fontName: "Staatliches", $fileName: "staatliches-v5-latin", $weights: ("400"), $types: woff);