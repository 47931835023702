
// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	font-size: rem($realFontSize/1.2);
	line-height: rem($baseLineHeight/1.2);
	margin-top: rem($heightDefault);
	display: block;
	z-index: 1;
	overflow: hidden;
	@include breakpoint(medium) {
		margin-top: rem($heightMedium);
	}
	@include breakpoint(large) {
		margin-top:  rem($heightLarge);
	}
	@include breakpoint(giant) {
		margin-top: rem($heightGiant);
		background-attachment: fixed;
	}
	@include breakpoint(huge) {
		font-size: rem($realFontSize/1.15);
		line-height: rem($baseLineHeight/1.15);	
		margin-top: rem($heightHuge);
	}
	@include breakpoint(full) {
		font-size: rem($realFontSize);
		line-height: rem($baseLineHeight);	
		margin-top: rem($heightFull);
	}

	body:not(.index) & {
		
	}
	>.row {
		height: 100%;
	}
	.claim {
		display: block;
		width: 100%;
		position: relative;
		text-align: center;
		@extend .paddingSection;
		@include breakpoint(small) {
			text-align: left;
		}
		.head {
			font-family: $displayFont;
			font-weight: 400;
			color:$dark;
			display: block;
			font-size: rem(28px);
			margin-bottom: rem($baseGap);
			@include breakpoint(small) {
				margin-bottom: 0;
			}
			@include breakpoint(medium) {
				font-size: rem(35px);
				line-height: rem(35px);	
			}
			@include breakpoint(large) {
				font-size: rem(40px);
				line-height: rem(40px);	
			}
			@include breakpoint(giant) {
				font-size: rem(55px);
				line-height: rem(45px);	
			}
			@include breakpoint(huge) {
				font-size: rem(60px);
				line-height: rem(50px);	
			}

			@include breakpoint(full) {
				font-size: rem(76px);	
				line-height: rem(70px);
			}
			.sub {
				color:$primary;
				display: block;
				font-size: rem(14px);
				letter-spacing: rem(4px);
				@include breakpoint(large) {
					font-size: rem(18px);	
				}
				@include breakpoint(giant) {
					font-size: rem(20px);	
				}
				@include breakpoint(huge) {
					font-size: rem(24px);	
				}

				@include breakpoint(full) {
					font-size: rem(30px);	
				}
			}
		}
		.btn {
			margin: 0 auto;
			width: rem(220px);
			display: block;
			@include breakpoint(small) {
				margin: 0;
			}
			@include breakpoint(medium) {
				display: inline-block;
				width: calc(50% - #{rem($baseGap/2/2)});
			}
			@include breakpoint(large) {
				width: calc(50% - #{rem($baseGap/2)});
			}
			&:first-of-type {
				margin-bottom: rem($baseGap/2);
				@include breakpoint(medium) {
					margin:0 rem($baseGap/2) 0 0;
				}
				@include breakpoint(large) {
					margin:0 rem($baseGap) 0 0;
				}
			}
		}
	}
	.cmsBackend & {
		height: rem(100px) !important;
	}

	.teaserHead {
		display: flex;
	    flex-wrap: nowrap;
	    overflow-x: auto;
	    overflow-y: hidden;
	    width: 100%;
	    flex: 0 1 auto;
		width: 100%;
		@include breakpoint(large) {
	    	flex-wrap: wrap;
			overflow: visible;
	    }
	    body:not(.index) & {
		}

		.trockenbau,
		.brandschutz,
		.waermedaemmfassaden {
			display: flex;
			width: 50%;
			height: rem(350px);
			flex: 0 0 auto;
			text-decoration: none;
			position: relative;
			overflow: hidden;
			background-position: center;
			z-index: 1;
			&:before {
				content:'';
				background-color: rgba($black,.25);
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				height: 100%;
				z-index: -1;
			}
			@include breakpoint(small) {
				height: rem(450px);
			}
			@include breakpoint(medium) {
				width: 33.3333%;	
				height: rem(550px);
			}
			@include breakpoint(large) {
				height: rem(600px);
			}
			@include breakpoint(giant) {
				height: rem(700px);
			}
			@include breakpoint(huge) {
				height: rem(770px);	
			}
			@include breakpoint(full) {
				height: rem(870px);
			}
			body:not(.index) & {
				height: rem(250px);
				@include breakpoint(giant) {
					height: rem(325px);	
				}
				@include breakpoint(huge) {
					height: rem(375px);		
				}
				@include breakpoint(full) {
					height: rem(400px);	
				}
			}

			&.lazyLoaded {
				background-size:cover;
			}
			&:hover {
				.rotate {
					transform: translateY(-20px) rotate(-90deg);
					body:not(.index) & {

					}
				}
			}
				
			.rotate {
			    transform:  translateY(0) rotate(-90deg);
			    transform-origin: left;
			    display: block;
			    white-space: nowrap;
			    line-height: 100%;
			    font-size: rem(20px);
			    color: $light;
			    font-weight: 700;
			    position: absolute;
			    bottom: rem(-11px);
			    left: calc(100% - #{rem(7px)});
    			width: 100%;
    			transition:.55s transform;
    			.cmsBackend & {
    				display: none;
    			}
    			@include breakpoint(small) {
    			    font-size: rem(30px);
    			    left: calc(100% - #{rem(10px)});
    			    bottom: rem(-17px);
    			}
    			@include breakpoint(giant) {
    				font-size: rem(35px);
    				bottom: rem(-20px);	
    				left: calc(100% - #{rem(11px)});			
    			}			
    			@include breakpoint(huge) {
    				font-size: rem(42px);
    				bottom: rem(-24px);
    				left: calc(100% - #{rem(13px)});		
    			}
    			@include breakpoint(full) {
    				font-size: rem(50px);
    				bottom: rem(-29px);
    				left: calc(100% - #{rem(16px)});
    			}
    			body:not(.index) & {
					font-size: rem(18px);
					bottom: rem(-11px);
					left: calc(100% - #{rem(6px)});
					@include breakpoint(giant) {
						font-size: rem(24px);
    					bottom: rem(-14px);
    					left: calc(100% - #{rem(9px)});
					}
					@include breakpoint(huge) {
						font-size: rem(28px);
    					bottom: rem(-16px);
					    left: calc(100% - #{rem(9px)});
					}
					@include breakpoint(full) {
						font-size: rem(30px);
    					bottom: rem(-17px);
					    left: calc(100% - #{rem(10px)});

					}
				}
			}
			
		}
		.trockenbau {
			@include responsiveImage('layout/brandschutz.jpg', (tiny), true);
		}
		.brandschutz {
			@include responsiveImage('layout/trockenbau.jpg', (tiny), true);
		}
		.waermedaemmfassaden {
			@include responsiveImage('layout/waermedaemmfassaden.jpg', (tiny), true);
		}
	}
	.handIcon {
	   display: flex;
		justify-content: center;
	    align-items: center;
		line-height: 100%;
		animation: handiconrotate 2s ease infinite;
		transition-timing-function: cubic-bezier(0.86, 0.01, 0.77, 0.78);
		transform-origin:50% 50%;
		position: relative;
		font-size: rem(12px);
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: rem(2px);
		font-style: normal;
		color:$primary;
		text-align: center;
		margin: rem($baseLineHeight/1.5) auto 0;
		i {
			font-size: rem(24px);
			font-weight: 400;
			margin-left: rem(9px);
		}
		@keyframes handiconrotate {
			0% {
				transform:translateX(0);
			}
			25% {
				transform:translateX(5px);
			}
			50% {
				transform:translateX(-5px);
			}
			75% {
				transform:translateX(5px);
			} 
			100% {
				transform:translateX(0);
			}
		}
		@include breakpoint(medium) {
			display: none;
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	position: relative;
	width: 100%;
	font-size: rem($realFontSize/1.2);
	line-height: rem($baseLineHeight/1.2);
	@include breakpoint(huge) {
		font-size: rem($realFontSize/1.15);
		line-height: rem($baseLineHeight/1.15);	
	}
	@include breakpoint(full) {
		font-size: rem($realFontSize);
		line-height: rem($baseLineHeight);	
	}
	.index & {
		z-index: 1;
	}
	body:not(.index) & {
		background-color: $light;
	}
	
	.teamBg {
		background-color: $four;
		display: flex;
		flex-wrap:wrap;
	    height: 100%;
	    align-items: center;
		width: 100%;
		text-align: center;
		padding: rem($baseGap*2);
		
		figcaption {
			width: 100%;
			display: block;
			
			.break {
				display: block;
				font-weight: 700;
				color:$dark;
			}
		}
	}
	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	ul:not(.unstyledList) {
		@extend .styledList;
		li {
			margin-bottom: rem($baseGap/2);
			&:last-of-type {
				margin-bottom: 0;
				padding-bottom: 0;
				border-bottom:0;
			}
		}
	}
	
	a {
		color:$secondary;
		&:hover {
			text-decoration: none;
		}
	}

	.lastP {
		display: flex;
		flex-wrap:wrap;
		width: 100%;
		align-items:center;
		p {
			&:last-of-type {
				@include breakpoint(medium) {
					margin-bottom: 0;
				}
			}
		}
		.pdf {
			margin-top: 0;
			@include breakpoint(medium) {
				margin-bottom: 0;
				margin-top: rem($baseLineHeight);
			}
			.ueberUns & {
				margin-bottom: rem($baseLineHeight);
				@include breakpoint(large) {
					margin-bottom: 0;
				}	
			}
			.aktuelles & {
				margin-bottom: rem($baseLineHeight);
			}
		}
		h1 {
			border:none;
			&:after {
				display: none;
			}
		}
		.btn {
			margin-bottom: rem($baseGap/2);
			&:last-of-type {
				margin-bottom: rem($baseLineHeight);
			}
			@include breakpoint(medium) {
				margin-bottom: 0;
				margin-top: rem($baseLineHeight);
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}

	//VERZEICHNIS
	.subNavigation {
		margin-bottom: 0;
		li {
			display: flex;
			margin-bottom: rem($baseGap)*2;

			a {
				display: flex;
				text-decoration: none;
				color:$dark;
				background:$light;
				box-shadow: 0 0 rem(20px) rgba(black,.05);
				padding:rem(30px);
				transition:.35s all;
				align-content:space-between;
				flex-wrap:wrap;
				font-weight: 300;
				&:hover {
					background-color: $secondary;
					span, b {
						color:$light;
						&.readMore {
							background-color: darken($secondary,15%);
						}
					}
				}
				b {
					transition:.35s all;
				}
				span {
					display: block;
					transition:.35s all;
					&:not(.readMore) {
						margin-bottom: rem(10px);
					}
					&.readMore {
						margin-bottom: 0;
						padding: rem(10px);
					}
				}
			}
			.btn {
				display: inline-block;
				width: auto;
			}
		}
	}

	
	.content {
		display: block;
		width: 100%;
		position: relative;
		overflow: hidden;
		background-color: $light;
		@extend .paddingSectionNoneBaseLineHeight;
		.index & {
			// p {
			// 	&:last-of-type {
			// 		margin-bottom: 0;
			// 	}
			// }
			.row {
				.row {
					.col {
						&:first-of-type {
							ul {
								
							}
						}
						// &:last-of-type {
						// 	p {
						// 		&:last-of-type {
						// 			margin-bottom:0;
						// 		}
						// 	}
						// }
					}
				}
			}
		}
		.objectFit { 
			
		}
		.bez {
			display: block;
			letter-spacing: rem(4px);
			font-size:rem(14px);
			font-family: $displayFont;
			font-weight: 400;
			color:$dark;
		}
		
		dl:not(.unstyledList) {
			@extend .styledList;
		}
		small {
			display: block;
			.break {
				display: block;
			}
		}
		> a {
			transition:.35s all;
			color:$secondary;
			font-weight: 700;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
		
		body:not(.index) & {
			background-color:$light;
			background:none;
			color:$primary;
			margin: 0;
			@extend .paddingSectionSmallNoneBaseLineHeight;
			
			.collapser {
				h2 {
					font-size: rem(15px);
					@include breakpoint(medium) {
						font-size: rem(17px);	
					}
					@include breakpoint(large) {
						font-size: rem(18px);		
					}
					@include breakpoint(giant) {
						font-size: rem(20px);	
					}
					@include breakpoint(huge) {
						font-size: rem(22px);
					}
					@include breakpoint(full) {
						font-size: rem(24px);	
					}
				}
			}
			ul {

				&:not(.unstyledList) {
					
					li {
						border-bottom:1px solid rgba($dark,.2);
						padding-bottom: rem($baseGap/2);
						margin-bottom: rem($baseGap/2);
						&:last-of-type {
							margin-bottom: 0;
							padding-bottom: 0;
							border-bottom:0;
						}
					}
				}
			}
		}
	}

	.contactBg {
		width: 100%;
		display: block;
		position: relative;
		@include responsiveImage('layout/contactBg.jpg', (tiny,medium,large), true);
		color:$light;
		display: flex;
		flex-wrap:wrap;
		align-items:flex-end;
		text-align: center;
		font-size: rem(16px);
		line-height: rem(30px);
		background-position: bottom center;
		@extend .paddingSection;
		padding-top: rem($paddingDefault*4);
		z-index: 1;
		@include breakpoint(medium) {
			text-align: left;
			padding-bottom: 0;
			padding-top: rem($paddingMedium*4);
			padding-bottom: 0;
		}
		@include breakpoint(large) {
			padding-top: rem($paddingLarge*4);
			font-size: rem(20px);
			line-height: rem(34px);	
		}
		@include breakpoint(giant) {
			padding-top: rem($paddingGiant*4);
			font-size: rem(24px);
			line-height: rem(41px);
		}
		@include breakpoint(huge) {
			background-attachment: fixed;
			font-size: rem(27px);
			line-height: rem(44px);	
			padding-top: rem($paddingHuge*4);
		}
		@include breakpoint(full) {
			font-size: rem(30px);
			line-height: rem(47px);	
			padding-top: rem($paddingFull*4);	
		}
		&.lazyLoaded {
			background-size:cover;
		}
		&:before {
			content:'';
			position: absolute;
			left:50%;
			bottom:0;
			height: rem($paddingDefault/1.5);
			width: 1px;
			transform:translateX(-50%) translateX(.5px);
			background-color: rgba($light,.55);
			@include breakpoint(medium) {
				display: none;
			}
		}
		&:after {
			content: '';
		    position: absolute;
		    right: 0;
		    bottom:0;
		    width: 100%;
		    height: 100%;
		    background-color:rgba($black,.55);
		    z-index: -1;
		}
		.default-middle {
			&:before {
				content:'';
				position: absolute;
				left: 50%;
				transform:translateX(-50%) translateX(.5px);
				bottom:0;
				height: 100%;
				width: 1px;
				background-color: rgba($light,.25);
				display: none;
				@include breakpoint(medium) {
					display: block;
				}
				@include breakpoint(large) {

				}
			}
		}
		dl {
			@include breakpoint(medium) {
				margin-bottom: 0;
			}
			dt {
				font-weight: 700;
			}
		}
		.break {
			display: block;
			a {
				color:$light;
				text-decoration: none;
				transition:.35s color;
				&:hover {
					color:$secondary;
				}
			}
		}
		.row .row {
			.col {
				&:last-of-type {
					.inner {
						@include breakpoint(medium) {
							text-align: left;
							position: relative;
						}
					}
				}
			}
		}
		.inner {
			display: block;
			width: 100%;
			
			@include breakpoint(medium) {
				text-align: right;
			
				padding-bottom:rem($paddingMedium);
			}
			@include breakpoint(large) {
				padding-bottom:rem($paddingLarge);
			}
			@include breakpoint(giant) {
				padding-bottom:rem($paddingGiant);
			}
			@include breakpoint(huge) {
				padding-bottom:rem($paddingHuge);
			}
			@include breakpoint(full) {
				padding-bottom:rem($paddingFull);
			}
		}
	}
	
	.contentSec {
		text-align: center;
		&:before {
			content:'';
			position: absolute;
			top:0;
			left:50%;
			transform:translateX(-50%) translateX(.5px);
			width: 1px;
			height: rem($paddingDefault);
			background-color: rem($dark,.35);
			@include breakpoint(medium) {
				height: rem($paddingMedium);
			}
			@include breakpoint(large) {
				height: rem($paddingLarge);
			}
			@include breakpoint(giant) {
				height: rem($paddingGiant);
			}
			@include breakpoint(huge) {
				height: rem($paddingHuge);
			}
			@include breakpoint(full) {
				height: rem($paddingFull);
			}
		}	
		strong {
			margin-top: rem($paddingSmallDefault);
			display: block;
			@include breakpoint(medium) {
				margin-top: rem($paddingSmallMedium);
			}
			@include breakpoint(large) {
				margin-top: rem($paddingSmallLarge);
			}
			@include breakpoint(giant) {
				margin-top: rem($paddingSmallGiant);
			}
			@include breakpoint(huge) {
				margin-top: rem($paddingSmallHuge);
			}
			@include breakpoint(full) {
				margin-top: rem($paddingSmallFull);
			}
		}
		ul {
			@include breakpoint(small) {
				display: flex;
    			justify-content: center;
			}
			li {
				padding-left: 0;
				&:last-of-type {
					margin-right: 0;
				}
				@include breakpoint(small) {
					margin-right: rem($baseGap);
				}
				&:before {
					display: none;
				}
			}
		}
		
	}
	.contentThird {
		background-color: $secondary;
		color:$light;	
		h2 {
			color:$light;
		}
	}
	.imgImpress {
		width: 100%;
		display: block;
		position: relative;
		height: rem(300px);
		@include responsiveImage('layout/impress.jpg', (tiny,medium,large), true);
		@include breakpoint(tiny) {
			height: rem(390px);
		}
		@include breakpoint(medium) {
			height: 100vh;
		}
		@include breakpoint(giant) {
			background-attachment: fixed;
		}
		&.lazyLoaded {
			background-size:cover;
		}
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	position: relative;
	width: 100%;
	display: block;
	font-size: rem($realFontSize/1.2);
	line-height: rem($baseLineHeight/1.2);
	@extend .paddingSection;
	text-align: center;
	@include breakpoint(small) {
		text-align: left;
	}
	@include breakpoint(huge) {
		font-size: rem($realFontSize/1.15);
		line-height: rem($baseLineHeight/1.15);	
	}
	@include breakpoint(full) {
		font-size: rem($realFontSize);
		line-height: rem($baseLineHeight);	
	}

	strong {
		display: block;
	}
	address {
		display: block;
		margin-bottom: rem($baseLineHeight);
		.break {
			display: block;
			@include breakpoint(large) {
				display: inline-block;
			}
		}
	}

	a {
		transition:.35s all;
		&:hover  {
			color:$secondary;
			text-decoration: none;
		}
	}
	dl {
		display: block;
		dt,dd {
			display: block;
			@include breakpoint(giant) {
				display: inline-block;
			}
		}
		dt {
			font-weight: 700;
			color:$dark;
			@include breakpoint(giant) {
				width: 25%;
			}
		}
		dd {
			@include breakpoint(giant) {
				width: 75%;
			}
		}
	}
	.none {
		display: none;
		@include breakpoint(small) {
			display: inline-block;
		}
		.flexBox {
			display: block;
			width: 100%;
			@include breakpoint(large) {
				display: flex;
				justify-content:space-between;
			}
			.naviAdd {
				display: block;
				@include breakpoint(large) {
					display: inline-block;
				}
				li {
					display: block;
					margin: 0;
					width: 100%;
					&.leistungen {
						display: none;
						@include breakpoint(large) {
							display: block;
						}
						a {
							&.leistungen {
								font-weight: 700;
								color:$dark;
							}
						}
					}
					.sub {
						padding-left: rem($baseGap/2);
						border-left:1px solid rgba($dark,.2);
					}
					a {
						transition:.35s color;
						text-decoration: none;
						color:$primary;
						font-weight: 300;

						&:hover {
							color:$secondary;
						}
						&.active {
							color:$secondary;
							font-weight: 700;
						}
					}
				}
			}
		}	
	}	
}



